// /api
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ApplicationConfigService } from 'src/app/core/config/application-config.service';
import { AppConstant } from 'src/app/shared/app-constant';
import { ClientDataStorageService } from 'src/app/shared/clientDataStorage/client-data-storage.service';

@Injectable({
  providedIn: 'root',
})
export class PickupOperationsService {

  private url = 'https://reqres.in/api/users';

     
  constructor(
    private http: HttpClient,
    private storageService: ClientDataStorageService,
    private applicationConfigService: ApplicationConfigService
  ) {}

  getUsers(page: number, perpage: number): Observable<any>{

    return this.http.get(this.url+'?page='+page+'&per_page='+perpage);

  }

  getOrdre(page: number, perpage: number): Observable<any>{
    return this.http
      .get(
      `${this.applicationConfigService.getEndpointFor(
        `/userFile/query` 
      )}?size=${perpage}&page=${page}&sort=id,desc`,
      AppConstant.httpOptions
    )
      .pipe(map((response) => response));
  }

  getOperateur(): Observable<any>{
    return this.http
      .get(
      `${this.applicationConfigService.getEndpointFor(
        `/managers/user/operators` 
      )}`,
      AppConstant.httpOptions
    )
      .pipe(map((response) => response));
  }


  affectation(idOrder: number, userId: string): Observable<any>{ 
    return this.http
      .get(
      `${this.applicationConfigService.getEndpointFor(
        `/managers/userFile/` 
      )}${idOrder}/${userId}`,
      AppConstant.httpOptions
    )
      .pipe(map((response) => response));
  }



  getOrdreByUserfile(idOrder: number): Observable<any>{
    return this.http
      .get(
      `${this.applicationConfigService.getEndpointFor(
        `/userFile/by-id-user-file/` 
      )}${idOrder}`,
      AppConstant.httpOptions
    )
      .pipe(map((response) => response));
  }

  getshippementbyOrdre(idOrder: number, page:number, perpage: number): Observable<any>{
    return this.http
      .get(
      `${this.applicationConfigService.getEndpointFor(
        `/userFileDetail/by-user-file/` 
      )}${idOrder}?size=${perpage}&page=${page}&sort=id,desc`,
      AppConstant.httpOptions
    )
      .pipe(map((response) => response));
  }
 

}
