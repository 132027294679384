import { Injectable } from '@angular/core';
import { ISidebar } from './sidebar.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  // Order public members before private members

  // Private members
  sidebarVisible = new BehaviorSubject<boolean>(true);

  // Public members
  sidebarVisible$ = this.sidebarVisible.asObservable();

  iconRelativepath = 'assets/content/images/sideBare/';

  menus: ISidebar[] = [
    {
      title: 'Tableau de bord',
      icon: this.iconRelativepath + 'table_board.svg',
      type: 'simple',
      routerLink: '',
      authorities: '',
    },
    {
      title: 'Position operateur',
      icon: this.iconRelativepath + 'table_board.svg',
      type: 'simple',
      routerLink: '/location',
      authorities: '',
    },
    
    {
      title: 'Shelving',
      icon: this.iconRelativepath + 'booking.svg',
      type: 'simple',
      routerLink: '/shelving',
      authorities: '',
    },
    {
      title: 'Codes Tracking',
      icon: this.iconRelativepath + 'booking.svg',
      type: 'simple',
      routerLink: '/code-tracking',
      authorities: '',
    },
    {
      title: 'Pickup operations',
      icon: this.iconRelativepath + 'booking.svg',
      type: 'simple',
      routerLink: '/pickup-operations',
      authorities: '',
    },
     {
       title: 'Administration',
       icon: this.iconRelativepath + 'admin.svg',
       type: 'dropdown',
       authorities: ['ROLE_ADMIN'],
       submenus: [
         {
          title: 'Users',
          icon: this.iconRelativepath + 'booking.svg',
          type: 'simple',
          routerLink: '/users',
          authorities: '',
        },
        {
          title: 'Zones',
          icon: this.iconRelativepath + 'booking.svg',
          type: 'simple',
          routerLink: '/zones',
          authorities: '',
        },
        {
          title: 'Agencies',
          icon: this.iconRelativepath + 'booking.svg',
          type: 'simple',
          routerLink: '/agencies',
          authorities: '',
        },
        {
          title: 'Cities',
          icon: this.iconRelativepath + 'booking.svg',
          type: 'simple',
          routerLink: '/cities',
          authorities: '',
        },
        {
          title: 'Sectors',
          icon: this.iconRelativepath + 'booking.svg',
          type: 'simple',
          routerLink: '/sectors',
          authorities: '',
        },
        {
          title: 'Permission Modules',
          icon: this.iconRelativepath + 'booking.svg',
          type: 'simple',
          routerLink: '/modules',
          authorities: '',
        },
        {
          title: 'Permission Functions',
          icon: this.iconRelativepath + 'booking.svg',
          type: 'simple',
          routerLink: '/functions',
          authorities: '',
        },
        {
          title: 'User Profile',
          icon: this.iconRelativepath + 'booking.svg',
          type: 'simple',
          routerLink: '/profiles',
          authorities: '',
        },
        {
          title: 'User types',
          icon: this.iconRelativepath + 'booking.svg',
          type: 'simple',
          routerLink: '/user-types',
          authorities: '',
        },
       ]
     }
  ];

  constructor() {}

  toggleSidebar(): void {
    this.sidebarVisible.next(!this.sidebarVisible.value);
    console.log('toggleSidebar >>> ', this.sidebarVisible.value);
  }

  toggle(): any {
    // this.toggled = !this.toggled;
  }

  getSidebarState(): any {
    // return this.toggled;
  }

  isSidebarVisible(): boolean {
    return this.sidebarVisible.value;
  }

  getMenuList(): any {
    return this.menus;
  }
}
