import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { ActivatedRoute  } from '@angular/router';
import { PickupOperationsService } from '../services/pickup-operations.service';
import { tracking } from 'src/app/shared/tracking/tracking.model';
import { FormBuilder } from '@angular/forms';
import { Pickup } from '../pickup.model';
import { OrdreShippement } from './ordre-shippement.model';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';

import $ from "jquery";
@Component({
  selector: 'milestone-pickup-details-ordre',
  templateUrl: './pickup-details-ordre.component.html',
  styleUrls: ['./pickup-details-ordre.component.scss']
})
export class PickupDetailsOrdreComponent implements OnInit{
  idOrder = '';

  users: any[] = [];
  page = 0;
  count = 0;
  tableSize = 10;
  tableSizes = [10, 20, 30];
  pickup?: Pickup;
  isShowDivIf = false;
  displayedColumns: string[] = ['select', 'Date de création', 'Référence expédition', 'Ville depart','Ville arrivee', 'Client', 'Statut' , 'action'];

  allColumns: string[] = ['select', 'Date de création', 'Référence expédition', 'Ville depart','Ville arrivee', 'Client', 'Statut', 'action'];

  visibleColumns = this.displayedColumns;


  dataSource = new MatTableDataSource<OrdreShippement>([]);
  selection = new SelectionModel<OrdreShippement>(true, []);

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  @ViewChild(MatPaginator) set _paginator(paginator: MatPaginator) {
    this.paginator = paginator;
    // this.dataSource.paginator = this.paginator;
  }


  @ViewChild(MatSelect) matSelect!: MatSelect;
  @ViewChild('TABLE') table!: ElementRef;

  totalItems = 0;

  tracking: tracking[] = [
    { title_status: 'Ordre de ramassage créé', 
      description_status: 'Ordre en attente d\'affectation',
       date_status:  '03/20/2021  10:06'},
    { title_status: 'Ordre de ramassage créé', 
    description_status: 'Ordre en attente d\'affectation',
      date_status:  '03/20/2021  10:06'},   
    { title_status: 'Incident', 
      description_status: 'Client injoignable',
       date_status: '03/20/2021  10:06' },

  ];

  dateRange = this.fb.group({
    start: [null],
    end: [null]
  })

  constructor(private route:ActivatedRoute,
     private pickupOperationService: PickupOperationsService, 
     private fb:FormBuilder,
     private notification: NotificationService ) {}


  ngOnInit():void {

    const id = this.route.snapshot.paramMap.get('idOrdre') ?? '';
    this.idOrder=id;
    this.getorderByuserfile(this.idOrder);
    this.getshippementbyOrdre(id);
    

    $('.mat-mdc-text-field-wrapper').css({
      'background' : 'white',
      'height' : '46px'
   });
  }


  getorderByuserfile(idOrder:any):void{
    console.log(idOrder);
    this.pickupOperationService.getOrdreByUserfile(idOrder)
      .subscribe((response: any) => {
        this.pickup = response;          
      });
  }


  getshippementbyOrdre(idOrder:any):void{
    this.pickupOperationService.getshippementbyOrdre(idOrder,this.page, this.tableSize)
      .subscribe((response: any) => {
        this.dataSource.data = response.content;
        this.totalItems  = response.totalElements;       
      });
  }

  isVisible(column: string): boolean {
    return this.visibleColumns.includes(column);
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle(): void {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  toggleDisplayDivIf(): void {
    this.isShowDivIf = !this.isShowDivIf;
  }

  onTableDataChange2(event: any): void {
    console.log(event.pageIndex);
    this.page = Number(event.pageIndex);
    this.tableSize = event.pageSize;
    this.getshippementbyOrdre(this.idOrder);
    this.table.nativeElement.scrollIntoView();

  }
  
}
