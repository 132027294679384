<div class="row mt-2">
<div class="col-md-9">
    <div class="card" style="border-left: 9px solid #385888;padding-top: 15px;">
        <div class="card-body">
            <div class="bloc_ordres">
           <div class="item_separ">
            <p class="title_details">Ref. Ordre:</p>
            <span class="value_details">{{idOrder}}</span>
          </div>
          <div class="item_separ">
            <div class="content_bloc">
                <div>
                <img src="assets/images/typedev.png" alt="">
                </div>
                <div>
                    <p class="title_details">Ramassage</p> 
                    <span class="value_details">25 Boulevard Ziraoui, Bourgogne, Casablanca</span>
                </div>
            </div>
          </div>
          <div class="item_separ">
            <div class="content_bloc">
                <div>
                <img src="assets/images/Vector.png" alt="">
                </div>
                <div>
                    <p class="title_details">Karim Ezzaim</p> 
                    <span class="value_details">06 08 08 05 07</span>
                </div>
            </div>
          </div>
          <div class="item_separ">
            <div class="content_bloc">
                <div>
                <img src="assets/images/livreur.png" alt="">
                </div>
                <div>
                    <p class="title_details">Hedi ZAZ</p> 
                    <span class="value_details">06 08 08 05 08</span>
                </div>
            </div>
          </div>

          <div class="item_separ">
            <div class="content_bloc">
                <div>
                <img src="assets/images/box-solid.png" alt="">
                </div>
                <div>
                    <p class="title_details">Colis</p> 
                    <span class="value_details">{{pickup?.countParcel}}</span>
                </div>
            </div>
          </div>
        </div>
        </div>
    </div>

    <div class="filter_ordre_details mt-8">

      <div>
      <mat-form-field appearance="fill">
        <mat-label>Choisir une date</mat-label>
        <mat-date-range-input [formGroup]="dateRange" [rangePicker]="picker">
          <input matStartDate formControlName="start" placeholder="Date de début">
          <input matEndDate formControlName="end" placeholder="Date de fin">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker>
          <button>today</button>
        </mat-date-range-picker>
      </mat-form-field>
      </div>
      
      <div>
        <div class="search-box">
        <input type="text" class="form-control" id="refOrdre" placeholder="Réf. Ordre">
        <i class="fas fa-search"></i>
      </div>
      </div>

      <div>
        <div class="search-box">
        <input type="text" class="form-control" id="ville_depart" placeholder="Ville de départ">
        <i class="fas fa-search"></i>
      </div>
      </div>
      
      <div>
        <div class="search-box">
        <input type="text" class="form-control" id="ville_depart" placeholder="Ville d'arrivée">
        <i class="fas fa-search"></i>
      </div>
      </div>
     
      
      <!-- Point de Livraison -->
      <div>
        <div class="search-box">
        <input type="text" class="form-control" id="client" placeholder="Client">
        <i class="fas fa-search"></i>
        </div>
      </div>
      
      

      
      <!-- Statut -->
      <div>
        <select class="form-select" id="statut">
            <option selected>Statut</option>
            <option value="1">En cours</option>
            <option value="2">Livré</option>
            <option value="3">Annulé</option>
        </select>
      </div>
      
      
      </div>
   

    

      <div class="">
        <div class="example-container mat-elevation-z8 " #TABLE>
          <table mat-table  #table [dataSource]="dataSource" class="table mat-elevation-z8">

            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox class="example-margin" (change)="$event ? masterToggle() : null"
                              [checked]="selection.hasValue() && isAllSelected()"
                              [indeterminate]="selection.hasValue() && !isAllSelected()">
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)">
              </mat-checkbox>
              </td>
            </ng-container>
            <!-- ID Column -->
        
          <ng-container matColumnDef="Date de création"  *ngIf="isVisible('Date de création')">
            <th mat-header-cell *matHeaderCellDef>Date de création</th>
            <td mat-cell *matCellDef="let element">{{ (element.createdDate * 1000) | date:'dd-MM-YYYY' }} <br> {{ (element.createdDate * 1000) | date:'HH:mm:ss' }}</td>
          </ng-container>

          <ng-container matColumnDef="Référence expédition"  *ngIf="isVisible('Référence expédition')">
            <th mat-header-cell *matHeaderCellDef>Référence d'expédition</th>
            <td mat-cell *matCellDef="let element"><a [routerLink]="['/pickup-shipement-details', element.shipment.idShipment ]">{{element.shipment.idShipment}}</a></td>
          </ng-container>

          <ng-container matColumnDef="Ville depart"  *ngIf="isVisible('Ville depart')">
            <th mat-header-cell *matHeaderCellDef>Ville de départ</th>
            <td mat-cell *matCellDef="let element">{{ element.shipment.cityDeparture.cityName}}</td>
          </ng-container>

          <ng-container matColumnDef="Ville arrivee"  *ngIf="isVisible('Ville arrivee')">
            <th mat-header-cell *matHeaderCellDef>Ville d'arrivé</th>
            <td mat-cell *matCellDef="let element">{{ element.shipment.cityArrival.cityName}}</td>
          </ng-container>

          <ng-container matColumnDef="Client"  *ngIf="isVisible('Client')">
            <th mat-header-cell *matHeaderCellDef>Client</th>
            <td mat-cell *matCellDef="let element">{{ (element.shipment.companySender == '') ? '-' : element.shipment.companySender}} <br> {{ element.shipment.gsmSender.replace('00212','0')}}</td>
          </ng-container>

          <ng-container matColumnDef="Statut"  *ngIf="isVisible('Statut')">
            <th mat-header-cell *matHeaderCellDef>Statut</th>
            <td mat-cell *matCellDef="let element">{{ element.shipment.currentStatus.description}}</td>
          </ng-container>

          <ng-container matColumnDef="action" >
            <th mat-header-cell *matHeaderCellDef><button (click)="toggleDisplayDivIf()">
              <iconify-icon icon="ant-design:setting-filled"></iconify-icon>
             
            </button>
        
            <div *ngIf="isShowDivIf" class="togglecolumn">
            <mat-selection-list [(ngModel)]="visibleColumns">
              <mat-list-option
                *ngFor="let column of allColumns"
                [value]="column"
                style="--mdc-checkbox-selected-icon-color: #1e3a8a"
              >
                {{ column}}
              </mat-list-option>
            </mat-selection-list>
            </div>
          </th>
            
            <td mat-cell *matCellDef="let element"></td>
          </ng-container>
        
          <!-- Name Column -->
        
          <tr mat-header-row *matHeaderRowDef="visibleColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: visibleColumns;"></tr>
          </table>
          <span *ngIf="totalItems == 0"  style="display: table;margin:10px auto">
            Aucune données trouvées
          </span>
          </div>
          <mat-paginator [length]="totalItems" [pageSize]="10" [pageSizeOptions]="[2, 5, 10, 20]" aria-label="{{ 'itemsParPage' }}" (page)="onTableDataChange2($event)" ></mat-paginator>

        <div>
      
          
        </div>

      
      </div>

</div>


<div class="col-md-3">
    
    <mat-accordion>
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header >
            <span class="title_accord">Suivi de l'ordre</span>
          </mat-expansion-panel-header>
  
          <ng-template matExpansionPanelContent >
            <hr style="margin-top: -7px;">
            <div class="history-tl-container">
            <ul class="tl" style="width:100%">
                <li  *ngFor="let track of tracking" class="tl-item {{track.title_status}}">
                     <div class="bloc_tracking">                   
                    <div class="item-title">
                       <p>{{track.title_status}}</p>
                       <span>{{track.description_status}}</span>
                    </div>
                    <div class="timestamp">
                        <span>{{track.date_status}}</span>             
                    </div>
                </div>
                </li>
                
                
            </ul>
                    </div>
          </ng-template>
        </mat-expansion-panel>
    
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <span class="title_accord">Messages associés</span>
          </mat-expansion-panel-header>
          <ng-template matExpansionPanelContent>
            <hr style="margin-top: -7px;">
          </ng-template>
        </mat-expansion-panel>


        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <span class="title_accord">Documents</span>
          </mat-expansion-panel-header>
          <ng-template matExpansionPanelContent>
            <hr style="margin-top: -7px;">
          </ng-template>
        </mat-expansion-panel>
    
       
      </mat-accordion>
</div>

 
</div>
